<template>
  <div class="skqlogin">
    <div class="logo">
      <img src="@assets/img/skqlogo.png" />
      <div class="name">斯凯奇劳务工自主服务平台</div>
    </div>
    <div class="title">欢迎回来！</div>
    <van-cell-group>
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field
        v-model="tel"
        type="tel"
        label=""
        placeholder="请输入手机号码"
        error-message=""
      />
      <!-- 输入任意文本 -->
      <van-field
        v-model="text"
        label=""
        maxlength="6"
        placeholder="请输入身份证后6位"
      />
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field
        v-model="code"
        type="digit"
        label=""
        placeholder="请输入验证码"
      >
        <template #button>
          <!-- <van-button size="small" :disabled="!tel || !text" @click="checkEmpid"
            >获取验证码</van-button
          > -->
          <van-button
            size="small"
            type="primary"
            disabled
            v-if="!showGetCode || countDown !== 0"
            >{{
              countDown === 0
                ? $t('salarySiginPassword.sspTxt8')
                : `${countDown}S${$t('salarySiginPassword.sspTxt9')}`
            }}</van-button
          >
          <van-button
            size="small"
            type="primary"
            :disabled="!tel || !text"
            v-if="showGetCode && countDown === 0"
            @click="b4getCode"
            >{{ $t('salarySiginPassword.sspTxt8') }}</van-button
          >
        </template>
      </van-field>
    </van-cell-group>
    <div class="sumbitbtn">
      <van-button type="info" class="login" :disabled="!code" @click="submit"
        >登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { getReq, getDynV8ApiToken } from '@api/wx.js'
import { Dialog, Toast, Notify } from 'vant'
import { getSmsCode } from '@api/login.js'
import { decrypt } from '@/utils/aes.js'
import { getOpenId } from '@/utils/auth'
export default {
  data() {
    return {
      v8apitoken: '',
      tel: '',
      text: '',
      empid: 0,
      // 短信验证变量 ----------------------
      code: '',
      showGetCode: true, // 显示获取验证码
      countDown: 0, // 验证码倒计时
      key: '', // 验证码绑定的key
      timer: null,
      leftToPath: '',
      openid: ''
      // ------------------------
    }
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      getDynV8ApiToken().then(res => {
        // 获取v8apitoken 后面自定义接口用到
        this.v8apitoken = res
        let hasOpenId = getOpenId()
        this.openid = hasOpenId
        // this.openid = '1111'
        if (this.openid) {
          this.getEmpidFromopenid()
        }
      })
    },
    getEmpidFromopenid() {
      //根据openid获取empid
      getReq(
        {
          autoid: 0,
          fixcode: 'a4184da3b0c74f868ee6639564fcf8f3',
          p01: this.openid
        },
        this.v8apitoken
      ).then(res => {
        const data = res.data[0]
        if (data) {
          this.empid = data.empid
          this.$router.push({
            path: '/skqcard',
            query: { empid: this.empid }
          })
        }
      })
    },
    b4getCode() {
      getReq(
        {
          autoid: 0,
          fixcode: 'c94beeafae6b4550b02b7497f7e3c5e0',
          p01: this.text,
          p02: this.tel
        },
        this.v8apitoken
      ).then(res => {
        const data = res.data[0]
        if (data.isinfo) {
          Toast(data.info)
        } else {
          this.empid = data.empid
          this.getCode()
        }
      })
    },
    // 发送验证码
    getCode() {
      getSmsCode({
        mobilephone: this.tel
      }).then(res => {
        if (res.iserror == 0) {
          Notify({
            type: 'success',
            message: this.$t('salarySiginPassword.sspTxt13')
          })
          let token = sessionStorage.getItem('token')
          let code = decrypt(token, res.topvalue)
          this.code = ''
          this.key = code
          if (code == 1234) {
            this.code = 1234
          }
        } else if (res.iserror == 1) {
          Notify({
            type: 'danger',
            message: this.$t('salarySiginPassword.sspTxt14') + res.errormsg
          })
        }
      })
      this.countDown = 59
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 确定
    submit() {
      if ((this.code = this.key)) {
        if (this.openid) {
          // 有openid，则绑定
          getReq(
            {
              autoid: 0,
              fixcode: '272f7b60288640a7b620a8cb03680d2c',
              p01: this.empid,
              p02: this.openid
            },
            this.v8apitoken
          ).then(res => {
            this.$router.push({
              path: '/skqcard',
              query: { empid: this.empid }
            })
          })
        } else {
          this.$router.push({
            path: '/skqcard',
            query: { empid: this.empid }
          })
        }
      } else {
        Notify({
          type: 'danger',
          message: this.$t('salarySiginPassword.sspTxt15')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.skqlogin {
  padding: 0 5vw 0 5vw;
  height: 100vh;
  background: #e7f0fd;
  align-items: center;
  .logo {
    img {
      height: 7vh;
      margin-bottom: 1vh;
    }
    .name {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }
  .title {
    font-size: 30px;
    font-weight: 30;
    margin: 0 auto 1em;
    color: #333;
    width: 580px;
  }
  .van-cell-group {
    margin: 1em auto 0;
    background: none;
    width: 580px;
    .van-field {
      border-radius: 20px;
      margin: 30px 0;
      .van-field__body {
        height: 100%;
      }
      /deep/.van-field__button {
        padding: 0;
        margin-top: -15px;
        .van-button {
          color: rgb(32, 129, 255);
          background: none;
          border: none;
        }
      }
    }
  }
  .sumbitbtn {
    margin: 100px 0;
    display: flex;
    justify-content: center;
    .login {
      width: 580px;
      border-radius: 20px;
    }
  }
}
</style>
